import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme({
  typography: {
    fontFamily: 'Prompt, sans-serif',
  },
  components: {
    MuiTab: {
        styleOverrides: {
            root: {
                fontSize: '20px',
                textTransform: 'none',
                fontWeight: 'bold',
                color: 'black', // สีฟอนต์เริ่มต้น
                '&.Mui-selected': {
                    color: '#2BAF2B', // สีฟอนต์เมื่อแท็บถูกเลือก
                },
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            indicator: {
                backgroundColor: '#2BAF2B', // สีของเส้นใต้เมื่อแท็บถูกเลือก
            },
        },
    },
},
});
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
    
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
