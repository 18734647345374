import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import taladHubLogo from './talad-hub-logo.png';
import axios from 'axios';
import AppHeaderBar from './features/shell/app-bar/AppHeaderBar';
import Home from './features/home/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TaladDetail from './features/talad-detail/talad-detail';

function App() {



  return (
    <div className="build/favicon.ico build/logo192.png build/logo512.png build/manifest.json build/robots.txt build/static build/index.html build/asset-manifest.json">

      <Router>
        <Routes>
          <Route path="/" element=
            {
              <div>
                <AppHeaderBar />
                <Home />
              </div>
            }
          />
          <Route path='/talad-detail' element={
            <div>
                <AppHeaderBar />
              <TaladDetail></TaladDetail>
            </div>
          }></Route>
          <Route path="/comming-soon" element={
            <header className="App-header">
              <img src={taladHubLogo} className="App-logo" alt="logo" />


              <p>
                See you soon.
              </p>
            </header>
          } />
        </Routes>
      </Router>

    </div>
  );
}

export default App;
