import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './carousel.css';


// Custom Left Arrow
const PrevArrow = (props:any) => {
    const { onClick } = props;
    return (
        <div className="custom-arrow prev-arrow" onClick={onClick}>
            <span>←</span>
        </div>
    );
}

// Custom Right Arrow
const NextArrow = (props:any) => {
    const { onClick } = props;
    return (
        <div className="custom-arrow next-arrow" onClick={onClick}>
            <span>→</span>
        </div>
    );
}

const CustomCarousel = ({ items }:any) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Number of visible items
        slidesToScroll: 1,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };

    return (
        <Slider {...settings}>
            {items.map((item:any, index:any) => (
                        <div key={index} className="carousel-item">
                            <div className="item-content">
                                {/* Customize your item here */}
                                {item}
                            </div>
                        </div>
                    ))}
        </Slider>
    );
};

export default CustomCarousel;
