import { useLocation, useParams } from "react-router-dom";
import AppHeaderBar from "../shell/app-bar/AppHeaderBar";
import MapComponent from "../shell/map/map";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Alert, Box, Button, ButtonGroup, CardMedia, CircularProgress, createTheme, Grid, Paper, Rating, Tab, Tabs, ThemeProvider, Tooltip, Typography } from "@mui/material";
import React from "react";
import Carousel from 'react-material-ui-carousel'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomCarousel from "../shell/carousel/carousel";
import './talad-detail.css'
import { BarChart, Gauge } from '@mui/x-charts';
import { HeatMapGrid } from 'react-grid-heatmap'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
//tab

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
    };
}
const yLabels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const xLabels = new Array(19).fill(0).map((_, i) => String(i + 6));


function TaladDetail() {
    const MySwal = withReactContent(Swal);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const [value, setValue] = React.useState(0);
    const [imagesItemBottom, setImageItemBottom] = React.useState<any[]>([]);

    // ดึง query string จาก URL
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('detailMarketId');

    const [taladDetail, setTaladDetail] = useState<any>(null);
    const [dataChart, setDataChart] = useState<any[]>([]);
    const [dataHeat, setDataHeat] = useState<any[]>([]);
    const [colorArray, setColorArray] = useState<any[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [rating, setRating] = useState(0);

    const [isVisible, setIsVisible] = useState(true);
    const [isPlayAnimate, setIsPlayAnimate] = useState(false);
    const sectionRef1 = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    useEffect(() => {
        const observerCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach((entry) => {
                if (entry.target === sectionRef1.current && !isPlayAnimate) {
                    setIsVisible(entry.isIntersecting);
                    if (entry.isIntersecting) {
                        setIsPlayAnimate(true);
                    }
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, { threshold: 0.2 });

        if (sectionRef1.current) observer.observe(sectionRef1.current);

        return () => {
            if (sectionRef1.current) observer.unobserve(sectionRef1.current);
        };
    }, [isPlayAnimate]);
    useEffect(() => {
        axios.get<any>(`https://apps.softsquaregroup.com/TaladHub.API/api/Market/${id}`)
            .then((response) => {
                console.log(response);
                setTaladDetail(response.data);
                const fetchedRating = response.data?.marketAdditionalInfoMap?.rating || 0;
                setRating(fetchedRating);

                var datatest = (response.data.marketPopularities) as any[];
                const getMaxValueForDay = (dayName: string) => {
                    var dayData = datatest.filter(x => x?.dayName === dayName);
                    const values = dayData.flatMap(data => data?.popularity);
                    return dayData ? Math.max(...values) : 0;
                };
                const dataHeat = yLabels.map(day => {
                    const dayData: number[] = [];
                    datatest.forEach(entry => {
                        if (entry.dayName === day) {
                            if (entry.hour >= 6 && entry.hour <= 24) {
                                dayData.push(entry.popularity);
                            }
                        }
                    });
                    console.log(dayData);
                    return dayData;
                });



                setDataHeat(dataHeat);

                // สร้างข้อมูลใหม่
                const data = [
                    getMaxValueForDay('Monday'), getMaxValueForDay('Tuesday'), getMaxValueForDay('Wednesday')
                    , getMaxValueForDay('Thursday'), getMaxValueForDay('Friday'), getMaxValueForDay('Saturday'), getMaxValueForDay('Sunday')
                ];
                setDataChart(data);
                const maxValue = Math.max(...data);
                const maxValueIndex = data
                    .map((value, index) => (value === maxValue ? index : -1))
                    .filter(index => index !== -1);
                const colors = Array.from({ length: 7 }, (_, index) =>
                    maxValueIndex.includes(index) ? "#2BAF2B" : "#C7F5C7FF"
                );
                setColorArray(colors);
                const dataItemBottom = response.data?.detailMarket?.imageMarkets?.map((item: any) => (
                    <CardMedia className={isVisible ? "animate__animated animate__flipInX  carousel-image" : "animate__animated animate__fadeOut carousel-image"}
                        key={item.imageUrl}
                        loading="lazy"
                        component="img"
                        image={item.imageUrl}
                    />
                )) || [];


                setImageItemBottom(dataItemBottom);
            }).finally(() => {
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching market details:', error);
            });
    }, [id]);



    return (
        <div className="box-detail">
            <div className="title-detail" >
                <h2 className={(isVisible && isPlayAnimate) ? "animate__animated animate__bounceIn" : ""}>{taladDetail?.marketName ?? ''}</h2>

            </div>
            <div className="talad-image-detail" ref={sectionRef1} >
                {
                    isLoading ?
                        <div className="talad-image">
                            <div className="skeleton-loader">
                            </div>
                        </div>
                        :
                        <CardMedia className={(isVisible && isPlayAnimate) ? "animate__animated animate__flipInX talad-image" : "talad-image"}
                            loading="lazy"
                            component="img"
                            image={taladDetail?.urlImageProfile}
                        />
                }

            </div>
            <div className="content-detail">
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="ข้อมูลตลาด" {...a11yProps(0)} />
                            <Tab label="ข้อมูลเชิงลึก" {...a11yProps(1)} />
                            <Tab label="เเผนผังตลาด" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Grid container className={(isVisible && isPlayAnimate) ? "animate__animated animate__slideInLeft" : ""}>
                            <Grid item md={3} lg={3}></Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} flexDirection={'column'}>
                                <h2 className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>ที่อยู่</h2>
                                <div className={(isVisible && isPlayAnimate) ? "animate__animated animate__slideInDown" : ""}>
                                    {taladDetail?.detailMarket?.addressDetail}
                                </div>
                                <h2 className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>เเผนที่</h2>
                                <div>
                                <MapComponent lat={Number(taladDetail?.marketAdditionalInfoMap?.coordinates.split(',')[0] ?? '0')} lng={Number(taladDetail?.marketAdditionalInfoMap?.coordinates.split(',')[1] ?? '0')} />
                                </div>
                                <h2 className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>รายละเอียดเพิ่มเติม</h2>
                                <ul className={(isVisible && isPlayAnimate) ? "animate__animated animate__slideInDown" : ""}>
                                    <li >ขนาดพื้นที่: {taladDetail?.detailMarket?.additionalInfo?.areaSize}</li>
                                    <li >ที่จอดรถ: {taladDetail?.detailMarket?.additionalInfo?.parkingSpots}</li>
                                    <li >ห้องน้ำ: {taladDetail?.detailMarket?.additionalInfo?.toilets}</li>
                                    <li >วันที่ทำการ: {taladDetail?.detailMarket?.additionalInfo?.operationTime}</li>
                                    <li >การเดินทาง: {taladDetail?.detailMarket?.additionalInfo?.transportation}</li>
                                </ul>
                                <h2 className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>จุดเด่น</h2>
                                <ul className={(isVisible && isPlayAnimate) ? "animate__animated animate__slideInDown" : ""}>
                                    {
                                        taladDetail?.detailMarket?.marketHighlights?.map((item: any) => (
                                            <li key={item.highlightId}>{item?.highlight}</li>
                                        ))
                                    }
                                </ul>
                                <h2 style={{ marginBottom: 'unset' }}>ค่าใช้จ่าย</h2>
                                <p style={{ marginTop: 'unset' }}>{taladDetail?.detailMarket?.marketContract.note}</p>
                                <ul className={(isVisible && isPlayAnimate) ? "animate__animated animate__slideInDown" : ""}>
                                    <li >ค่าเเรกเข้า: {taladDetail?.detailMarket?.additionalInfo?.initialCost}</li>
                                    <li >ค่าประกัน: {taladDetail?.detailMarket?.additionalInfo?.insurancePremium}</li>
                                    <li >ค่าเช่าล่วงหน้า: {taladDetail?.detailMarket?.additionalInfo?.advanceRent}</li>
                                    <li >ค่าน้ำ: {taladDetail?.detailMarket?.additionalInfo?.waterFee}</li>
                                    <li >ค่าไฟ: {taladDetail?.detailMarket?.additionalInfo?.electricityBill}</li>
                                    <li >ค่าใช้จ่ายอื่น ๆ: {taladDetail?.detailMarket?.additionalInfo?.otherExpenses}</li>
                                </ul>

                            </Grid>
                            <Grid item md={1} lg={1}></Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <h2 className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>สถานที่ใกล้เคียง</h2>
                                <ul className={(isVisible && isPlayAnimate) ? "animate__animated animate__slideInDown" : ""}>
                                    {
                                        taladDetail?.detailMarket?.nearbyPlaces?.map((item: any) => (
                                            <li key={item.nearbyPlaceId}>{item?.nearbyPlace}</li>
                                        ))
                                    }
                                </ul>
                            </Grid>
                            <Grid item md={2} lg={2}></Grid>
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1} >
                        <Grid container className={(isVisible && isPlayAnimate) ? "animate__animated animate__slideInRight" : ""}>
                            <Grid item md={2} lg={2}></Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} flexDirection={'column'}>
                                <div>
                                    <Grid container >
                                        <Grid item md={2} lg={2}>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} flexDirection={'column'} className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>

                                            <h2>รีวิว</h2>
                                            <Tooltip title={rating} placement="left-start">
                                                <div className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn box-rating" : "box-rating"}>

                                                    <Rating name="half-rating" defaultValue={3} value={rating} precision={0.1} readOnly style={{ color: '#D3E73E' }} size="large" />
                                                    <b>({taladDetail?.marketAdditionalInfoMap?.ratingCount})</b>
                                                </div>
                                            </Tooltip>
                                            <h2 className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>ความหนาเเน่น</h2>
                                        </Grid>
                                        <Grid item md={2} lg={2}>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="bar-chart">
                                    <Grid container >
                                        <Grid item md={2} lg={2}>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} flexDirection={'column'} className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>
                                            <BarChart
                                                series={[
                                                    { data: dataChart, color: 'red' },
                                                ]}
                                                height={290}
                                                xAxis={[{
                                                    data: yLabels, scaleType: 'band', colorMap: {
                                                        type: 'ordinal',
                                                        colors: colorArray,
                                                    }
                                                }]}
                                                margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
                                            />
                                            <p style={{ textAlign: 'center' }}>ความหนาแน่น รายวัน</p>
                                        </Grid>
                                        <Grid item md={2} lg={2}>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="heat-chart">
                                    <Grid container >

                                        <Grid item md={2} lg={2}>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} flexDirection={'column'} className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>
                                            <div style={{ overflow: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                <HeatMapGrid
                                                    data={dataHeat}
                                                    xLabels={xLabels}
                                                    yLabels={yLabels}
                                                    // Render cell with tooltip
                                                    cellRender={(x, y, value) => (
                                                        <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>
                                                    )}
                                                    xLabelsStyle={(index) => ({
                                                        color: index % 2 ? 'transparent' : '#777',
                                                        fontSize: '.8rem'
                                                    })}
                                                    yLabelsStyle={() => ({
                                                        fontSize: '.7rem',
                                                        textTransform: 'uppercase',
                                                        color: '#777'
                                                    })}
                                                    cellStyle={(_x, _y, ratio) => ({
                                                        background: `rgb(12, 160, 44, ${ratio})`,
                                                        fontSize: '.8rem',
                                                        color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`
                                                    })}
                                                    cellHeight='2rem'
                                                    xLabelsPos='bottom'
                                                    onClick={(x, y) => MySwal.fire({
                                                        icon: 'info',
                                                        title:
                                                            <div>
                                                                <p style={{ color: '#2BAF2B', margin: 'unset' }}>{yLabels[x]}</p>
                                                                <p style={{ margin: 'unset', fontSize: '22px' }}>ความหนาเเน่น: <b>{dataHeat[x][y]}</b> คน</p>
                                                                <p style={{ margin: 'unset', fontSize: '22px' }}>  ในชั่วโมงที่ <b>{xLabels[y]}</b> </p>
                                                            </div>,
                                                        didOpen: () => {

                                                        },
                                                    })}
                                                    yLabelsPos='left'
                                                    square
                                                />
                                                <p style={{ textAlign: 'center' }}>ความหนาแน่น แต่ละวัน/รายชั่วโมง จากความหนาแน่นมากที่สุด</p>
                                            </div>
                                        </Grid>
                                        <Grid item md={2} lg={2}>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item md={2} lg={2}></Grid>
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                       <p style={{textAlign:'center'}}>Comming Soon....</p> 
                    </CustomTabPanel>
                </Box>

            </div>
            <div style={{ backgroundColor: '#F8F7F4', width: '100%' }}>
                <Grid container>
                    <Grid item xs={1} sm={1} md={2} lg={2} flexDirection={'column'}>
                    </Grid>
                    <Grid item xs={10} sm={10} md={8} lg={8} flexDirection={'column'} style={{ paddingBottom: '50px' }}>
                        <h2 className={(isVisible && isPlayAnimate) ? "animate__animated animate__zoomIn" : ""}>รูปภาพทั้งหมด</h2>
                        {
                            isLoading ?
                                <div className="skeleton-loader" style={{ width: '100%', height: '200px', borderRadius: '15px' }} >
                                </div> :
                                <CustomCarousel items={imagesItemBottom} />
                        }
                    </Grid>
                    <Grid item xs={1} sm={1} md={2} lg={2} flexDirection={'column'}>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default TaladDetail