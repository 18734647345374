import { Button, Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, Pagination, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import './Home.css';
import mobileDetail from '../../assets/mobile-detail.png';
import mobileDetail2 from '../../assets/mobile-home.png';
import mobileDetail3 from '../../assets/mobile-detail-2.png';
import graph from '../../assets/graph.png';
import target from '../../assets/target-group.png';
import weather from '../../assets/weather-forecast.png';
import nearby from '../../assets/nearby-place.png';
import suggestion from '../../assets/suggestion.png';
import 'animate.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import tiktok from '../../assets/tiktok.png'
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLine,faFacebook, faTiktok, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

class TaladDTO {
    marketId!: number;
    marketName!: string;
    marketRating!: string;
    marketAddress!: string;
    marketPrice!: string;
    urlImageProfile!: string;
    detailMarket!: TaladDetailDTO;
}

class TaladDetailDTO {
    detailMarketId!: number;
    marketId!: number;
    addressDetail!: string;
    googleMapLink!: string;
}

class PaginationResult<T> {
    data: T[] = [];
    totalCount!: number;
}

function Home() {
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [taladList, setTaladList] = useState<TaladDTO[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pages, setPages] = useState<number>(0);
    const [isVisible, setIsVisible] = useState(true);
    const [isVisible2, setIsVisible2] = useState(true);
    const [isVisible3, setIsVisible3] = useState(true);
    const [isVisible4, setIsVisible4] = useState(true);
    const [isPlayAnimate, setIsPlayAnimate] = useState(false);
    const [isPlayAnimate2, setIsPlayAnimate2] = useState(false);
    const [isPlayAnimate3, setIsPlayAnimate3] = useState(false);
    const [isPlayAnimate4, setIsPlayAnimate4] = useState(false);
    const sectionRef1 = useRef(null);
    const sectionRef2 = useRef(null);
    const sectionRef3 = useRef(null);
    const sectionRef4 = useRef(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isAnimation, setIsGoBackOrNext] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const observerCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach((entry) => {
                if (entry.target === sectionRef1.current && !isPlayAnimate) {
                    setIsVisible(entry.isIntersecting);
                    if (entry.isIntersecting) {
                        setIsPlayAnimate(true);
                    }
                } else if (entry.target === sectionRef2.current&& !isPlayAnimate2) {
                    setIsVisible2(entry.isIntersecting);
                    if (entry.isIntersecting) {
                        setIsPlayAnimate2(true);
                    }
                }
                else if (entry.target === sectionRef3.current && !isPlayAnimate3) {
                    setIsVisible3(entry.isIntersecting);
                    if (entry.isIntersecting) {
                        setIsPlayAnimate3(true);
                    }
                }
                else if (entry.target === sectionRef4.current && !isPlayAnimate4) {
                    setIsVisible4(entry.isIntersecting);
                    if (entry.isIntersecting) {
                        setIsPlayAnimate4(true);
                    }
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, { threshold: 0.2 });

        if (sectionRef1.current) observer.observe(sectionRef1.current);
        if (sectionRef2.current) observer.observe(sectionRef2.current);
        if (sectionRef3.current) observer.observe(sectionRef3.current);
        if (sectionRef4.current) observer.observe(sectionRef4.current);

        return () => {
            if (sectionRef1.current) observer.unobserve(sectionRef1.current);
            if (sectionRef2.current) observer.unobserve(sectionRef2.current);
            if (sectionRef3.current) observer.unobserve(sectionRef3.current);
            if (sectionRef4.current) observer.unobserve(sectionRef4.current);
        };
    }, [isPlayAnimate,isPlayAnimate2,isPlayAnimate3,isPlayAnimate4]);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    useEffect(() => {
        axios.get<PaginationResult<TaladDTO>>('https://apps.softsquaregroup.com/TaladHub.API/api/Market', { params: { page: page, pageSize: pageSize } })
            .then((response) => {
                setTotalCount(response.data.totalCount);
                setTaladList(response.data.data);
                const pointNumber = response.data.totalCount % pageSize;
                let pages: number = (response.data.totalCount / pageSize) | 0;
                if (pointNumber != 0) {
                    pages++;
                }
                // console.log(pages);
                setPages(pages);
                setCurrentPage(page);
            });

    }, [page]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (currentPage < value) // go next na jaa
        {
            setIsGoBackOrNext(true);
        }
        else {
            setIsGoBackOrNext(false);
        }
        setPage(value);
    };
    const handleButtonClick = () => {
        navigate('/comming-soon');
    };

    const clickToDetailPage =(marketId:number)=>{
        navigate(`/talad-detail?detailMarketId=${marketId}`);
    }
    return (
        <div className="home-compoent">
            <div ref={sectionRef1} className="section1">
                <div className={(isVisible && isPlayAnimate) ? "animate__animated animate__flipInX promote-talad" : "promote-talad"} >ข้อมูลตลาดมากกว่า 1,837 เเห่ง</div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginTop: "10px", rowGap: "1px" }}>
                    <b className={(isVisible && isPlayAnimate) ? "animate__animated animate__bounceIn text-hightlight" : "text-hightlight"}>ผู้เชี่ยวชาญข้อมูลเชิงลึก</b>
                    <b className={(isVisible && isPlayAnimate) ? "animate__animated animate__bounceIn text-hightlight" : "text-hightlight"}>สำหรับ<b className="highlight-green">ตลาด</b></b>
                    <div className="align-phone-design">
                        <CardMedia
                            className={(isVisible && isPlayAnimate) ? "animate__animated animate__fadeInLeft" : ""}
                            component="img"
                            height="400"
                            image={mobileDetail}
                            alt="green iguana"
                        />
                        <CardMedia
                            className={(isVisible && isPlayAnimate) ? "animate__animated animate__fadeIn" : ""}
                            component="img"
                            height="400"
                            image={mobileDetail2}
                            alt="green iguana"
                        />
                        <CardMedia
                            className={(isVisible && isPlayAnimate) ? "animate__animated animate__fadeInRight " : ""}
                            component="img"
                            height="400"
                            image={mobileDetail3}
                            alt="green iguana"
                        />
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button
                        onClick={handleButtonClick}
                        className={(isVisible && isPlayAnimate) ? "animate__animated animate__lightSpeedInLeft" : ""}
                        variant="contained" style={{ fontSize: "20px", backgroundColor: "#00B900", borderRadius: "50px", paddingLeft: "40px", paddingRight: "40px" }}>
                        เริ่มเลย!
                    </Button>
                </div>
            </div>
            <div ref={sectionRef2} className="section2">
                <h1 className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn" : ""}><b className="highlight-green">ฟีเจอร์</b>ของเรา</h1>
                <div className="features-all">
                    <div className="features-box">
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content" : "feature-content"}>
                            <CardMedia
                                component="img"
                                height="80"
                                image={graph}
                                alt="ความหนาเเน่น"
                            />
                        </div>
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content-title" : "feature-content-title"}>
                            <h2>ความหนาเเน่น</h2>
                            <span>
                                ความหนาแน่นของสถานที่ต่าง ๆ 
                                โดยมีการแสดงผลแบบแยกรายวัน และรายชั่วโมง
                                เพื่อให้สามารถคาดคะเนช่วงเวลา ที่มีโอกาสขายดีมากที่สุดได้
                            </span>
                        </div>
                    </div>
                    <div className="features-box">
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content" : "feature-content"}>
                            <CardMedia
                                component="img"
                                height="80"
                                image={target}
                                alt="กลุ่มลูกค้า"
                            />
                        </div>
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content-title" : "feature-content-title"}>
                            <h2>กลุ่มลูกค้า</h2>
                            <span>
                                วิเคราะห์กลุ่มลูกค้าของสถานที่ต่าง ๆ
                                เพื่อเพิ่มโอกาส ในการเข้าถึงกลุ่มลูกค้าที่ต้องการ
                            </span>
                        </div>
                    </div>
                    <div className="features-box">
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content" : "feature-content"}>
                            <CardMedia
                                component="img"
                                height="80"
                                image={weather}
                                alt="พยากรณ์อากาศ"
                            />
                        </div>
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content-title" : "feature-content-title"}>
                            <h2>พยากรณ์อากาศ</h2>
                            <span>
                                แจ้งเตือนสภาพอากาศ เนื่องจากเป็นปัจจัยสำคัญ ที่จะลดโอกาสในการเพิ่มยอดขาย
                            </span>
                        </div>
                    </div>
                    <div className="features-box">
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content" : "feature-content"}>
                            <CardMedia
                                component="img"
                                height="80"
                                image={nearby}
                                alt="สถานที่ใกล้เคียง"
                            />
                        </div>
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content-title" : "c"}>
                            <h2>สถานที่ใกล้เคียง</h2>
                            <span>
                                 แสดงผลสถานที่ใกล้เคียง <br/>เพื่อประกอบการตัดสินใจ ในการลงพื้นที่ขายสินค้า
                            </span>
                        </div>
                    </div>
                    <div className="features-box">
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content" : "feature-content"}>
                            <CardMedia
                                component="img"
                                height="80"
                                image={suggestion}
                                alt="เเนะนำสินค้า"
                            />
                        </div>
                        <div className={(isVisible2 && isPlayAnimate2) ? "animate__animated animate__bounceIn feature-content-title" : "feature-content-title"}>
                            <h2>เเนะนำสินค้า</h2>
                            <span>
                                แนะนำสินค้าที่เพิ่มโอกาสในการ <br/>เพิ่มยอดขาย ช่วยตัดสินใจ หากคุณยังเลือกสินค้าไม่ได้
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={sectionRef3} className="section3">
                <h1 className={(isVisible3 && isPlayAnimate3) ? "animate__animated animate__bounceIn" : ""}>ข้อมูล<b className="highlight-green">ตลาด</b>ทั้งหมด</h1>
                <div className="talads-all">
                    <Grid container spacing={7} style={{ padding: "50px" }}>
                        {taladList.map(talad => (
                            <Grid item xs={12} sm={6} md={3} lg={3} key={talad.marketId}>
                                <Card   style={{ borderRadius: "30px" }} className={classNames({
                                    'animate__animated animate__fadeInRight': isVisible3 && isAnimation,
                                    'animate__animated animate__fadeInLeft': isVisible3 && !isAnimation,
                                                })}>
                                    <CardActionArea onClick={()=>clickToDetailPage(talad.marketId)}>
                                        <CardMedia
                                            component="img"
                                            height="250"
                                            image={talad.urlImageProfile}
                                            alt={talad.marketName}
                                        />
                                        <CardContent>
                                            <Typography
                                                gutterBottom variant="body1"
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 1,
                                                }}
                                            >
                                                {talad.marketName}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {talad.marketAddress}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <div className="controls-table-talads">
                        <Pagination className="Paginator"
                            count={pages}
                            page={page}
                            onChange={handleChange}
                        />
                        {/* <p className="flex-style"> {"Page: "+page}</p>
                        <p>{"Page Size: "+pageSize}</p>
                        <p>{"Pages:" +pages}</p>
                        <p>{"total talads: "+totalCount}</p> */}
                    </div>
                </div>
            </div>
            <div ref={sectionRef4} className="section4">
                <h1 className={(isVisible4 && isPlayAnimate4) ? "animate__animated animate__fadeIn" : ""}>Talad<b style={{ color: "#2BAF2B" }}>Hub</b></h1>
                <span>
                    แพลตฟอร์มที่รวบรวมข้อมูลตลาดในประเทศไทย และวิเคราะห์ข้อมูลเชิงลึก เพื่อเพิ่มโอกาสเพิ่มยอดขาย
                </span>
                <div className="social-box">
                    <div onClick={() => window.open("https://line.me/R/ti/p/@784xkpyg?oat_content=url&ts=10030214", "_blank")} className={(isVisible4 && isPlayAnimate4) ? "button-line animate__animated animate__fadeIn" : " button-instagram"}>
                        <FontAwesomeIcon icon={faLine} />
                        Line
                    </div>
                    <div onClick={() => window.open("https://www.instagram.com/taladhub/", "_blank")} className={(isVisible4 && isPlayAnimate4) ? "button-instagram animate__animated animate__fadeIn" : " button-instagram"}>
                        <FontAwesomeIcon icon={faInstagram} />
                        Instagram
                    </div>
                    <div onClick={() => window.open("https://www.youtube.com/@TaladHub", "_blank")} className={(isVisible4 && isPlayAnimate4) ? "button-youtube animate__animated animate__fadeIn" : " button-youtube"}>
                        <FontAwesomeIcon icon={faYoutube} />
                        YouTube
                    </div>
                    <div onClick={() => window.open("https://www.facebook.com/profile.php?id=61565812510532", "_blank")} className={(isVisible4 && isPlayAnimate4) ? "button-facebook animate__animated animate__fadeIn" : " button-facebook"}>
                        <FontAwesomeIcon icon={faFacebook} />
                        Facebook
                    </div>
                    <div onClick={() => window.open("https://www.tiktok.com/@taladhub", "_blank")} className={(isVisible4 && isPlayAnimate4) ? "button-tiktok animate__animated animate__fadeIn" : " button-tiktok"}>
                        <FontAwesomeIcon icon={faTiktok} />
                        TikTok
                    </div>
                </div>

            </div>
            {/* <h2>Talad List</h2>
            <p>Page: {page}</p>
            <p>Page Size: {pageSize}</p>
            <p>Pages: {pages}</p>
            <p>{totalCount}</p>
            <div className="Data-display">
                {taladList.map(talad => (
                    <Card sx={{ maxWidth: 200 }} key={talad.marketId}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={talad.urlImageProfile}
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom variant="body1"
                                    component="div"
                                    sx={{
                                        display: '-webkit-box',
                                        overflow: 'hidden',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 1,
                                    }}
                                >
                                    {talad.marketName}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {talad.marketAddress}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </div>

            <Pagination className="Paginator"
                count={pages}
                page={page}
                onChange={handleChange}
            /> */}
        </div>
    );
}

export default Home;