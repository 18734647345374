import { Box, Button, Card, CardActionArea, Grid2, IconButton, Toolbar, Typography } from "@mui/material"
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function AppHeaderBar() {
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(true);
  const [isPlayAnimate, setIsPlayAnimate] = useState(false);
  const sectionRef = useRef(null);
  const clickHome =()=>{
    navigate('/');
}
  useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!entry.isIntersecting && isPlayAnimate) {
            setIsVisible(false); 
          } else {
            setIsVisible(true);
          }
        },
        { threshold: .5 }
      );
  
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }
  
      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }, []);

  return (
    <div style={{backgroundColor:"#F8F7F4"}} ref={sectionRef}>
      <Grid2 container spacing={2} >
          <Grid2 size={12} textAlign={"center"}  >
            <h1 onClick={clickHome} className={isVisible ? "animate__animated animate__bounceIn": "animate__animated animate__slideInDown"}><span  style={{ cursor:'pointer'}} >Talad<b style={{ color: "#2BAF2B", }}>Hub</b></span></h1>
          </Grid2>
      </Grid2>
    </div>
    // <Box sx={{ flexGrow: 1 }}>
    //   <AppBar color="transparent" position="static">
    //     <Toolbar>
    //       <IconButton
    //         size="large"
    //         edge="start"
    //         color="inherit"
    //         aria-label="menu"
    //         sx={{ mr: 2 }}
    //       >
    //         <MenuIcon />
    //       </IconButton>
    //       <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
    //         TaladHub
    //       </Typography>
    //       <Button color="inherit">Login</Button>
    //     </Toolbar>
    //   </AppBar>
    // </Box>
  );
}

export default AppHeaderBar